<div class="container cart-con">
  <p class="productlist-p">DEVASNDOLLSCHI` is your one stop shop providing you with the essentials to keep you up to speed in your beauty routine.<img  class="productlist-img"   src="assets/DollsandBags.png" alt="Dolls  and bag"></p> 
    <h1 class="cart-list-h1">Shopping Cart</h1>
    <div class="cart-borderBox">
    <div class="inside-bB">
    <div *ngIf="cartitems$.length === 0"class="alert alert-info">Your Cart is Empty</div> 
    
    <div *ngIf="product">
      <!--<div>{{product.name}}</div>-->
      <input type="number" [(ngModel)]="product.num" min="0"/>
    </div>
    
    <!--<ul *ngIf="cartItems.length >  0" class="list-group">-->
      <div  *ngFor="let item of cartitems$; let i = index">
        <app-cart-item  [cartItem]="item"></app-cart-item>  
      </div>
      
      <li class="list-group-item cart-list-item">
       <strong>Quantity {{itemTotal$}}</strong>
      </li> 
      <li class="list-group-item active cart-list-size">
        <strong>Total: {{ cartTotal$ | currency}}</strong>  
      </li>
      <li class="btn btn-primary cart-bbprimary">
        <a class="cart-prod-route"  routerLink="/product">Continue Shopping</a>&nbsp; 
      </li>
      <li class="btn  cart-bbprimary2"  style=" height:400; width:210px; margin-top: 20px; background-color: #D30169;">
        <a class="cart-check-route" routerLink="/checkout">Go To Checkout</a>&nbsp; 
      </li>
      
    </div>
    </div>
    </div>