

<div class="mainview" >  
    <app-header></app-header>
    <alert></alert>
     <router-outlet></router-outlet> 
      <app-footer></app-footer>
      <!--<div class="content"><span style="visibility: hidden">DivasandDollsChi app is running!</span></div>-->
</div> 

    

 
