 <!-- Jumbotron -->
 <div class="container cotactus-contain">
    <div class="jumbotron custom-jumbotron">
      
      <h1 class="h1-contactus contactus-size2"><span class="decor">Contact Us</span></h1>      
      <p class="contactus-size2">Thank you for your for visiting DivasNDollsChi`. Contact us using the information below. For additional information on our <span class="h1-contactus-color">Merchandise</span><span class="h1-contactus-color"></span> visit our web site.</p>
      <hr>
  
     <div class="container-fluid"> 
      <div class="row">                                                                                                                                                                                                          
      <div  class="col-3">
        <h2 class="custom-head2 contactus-size2">The DivasNDollsChi` Contacts</h2>
        <p class="contactus-size" >
            United States<br>
            Phone: 	&#40;773&#41;532-9183<br>
            Chicago IL 60655</p>          
      </div>
      <div class="col-4"><nav></nav>
        <h2 class="custom-head2">Business Inquiries</h2>
        <p class="contactus-size "><a class="decor">DivasNDollsChi&#64;att.net</a></p>          
     </div>
     <div class="col-4">
        <h2 class="custom-head2">Social Media Channels</h2>
        <p class="contactus-size">Follow <span class="h1-contactus-color"> DivasNDollsChi` </span> on LinkedIn</p>          
     </div>
     </div>
    </div>
  <br>
    <div class="container-fluid">
    <div class="row">
     <div class=" col-4">
        <h2 class="custom-head2">Web Questions or Comments</h2>
          <a class="custom-head2 contactus-size">DivasNDollsChi&#64;att.net</a>          
     </div>
     <div class="col-4">
        <h2 class="custom-head2">General Inquiries</h2>
          <a class="custom-head2 contactus-size">DivasNDollsChi&#64;att.net</a>          
     </div> 
     </div>
     </div>
     </div>
    </div>
  
  
