<!--<p>salel comtainerist-list works!</p>-->
<div class=" container dollsandbag">
    <p class="productlist-p">DEVASNDOLLSCHI` is your one stop shop providing you with the essentials to keep you up to speed in your beauty routine.<img  class="productlist-img"   src="assets/DollsandBags.png"></p></div> 
    <h1 class="salelist-h1">Sales</h1> 
     
    <div *ngIf="saleitem$.length === 0"class="alert alert-info">There are No Sales at This Time</div> 


       <div class="container">
       <div class="row">   
        <div class="col-md-2"  *ngFor="let sale of sales | paginate : {
          itemsPerPage: pageSize,
          currentPage: p,
          totalItems: count
         
        };
  
        let i = index
       
        "
     
         [class.active]="i == currentIndex"
        >  
        
        <app-saleitem [saleItem]="sale" [addedToWishlist]="wishlist.indexOf(product._id) >= 0"></app-saleitem>
        <!--<app-saleitem [saleItem]="sale" [addedToWishlist]="wishlist.indexOf(sale._id) >= 0"></app-saleitem>-->

      
        </div>
         </div>
  
     <div class="col-md-12">
    <pagination-controls class="float-right"
      previousLabel="Prev"
      nextLabel="Next"
      [responsive]="true"
      (pageChange)="p = $event"
      (pageBoundsCorrection)="handlePageChange($event)"
      directionLinks="true">
    
    </pagination-controls>
  </div>
  </div>