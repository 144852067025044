<div class="dollsandbag">
  <p class="productlist-p">DEVASNDOLLSCHI` is your one stop shop providing you with the essentials to keep you up to speed in your beauty routine.<img class="productlist-img"  src="assets/DollsandBags.png"></p>  
  
</div>
     <div class="container">
     <div class="row">   
      <div class="col-md-2"  *ngFor="let product of products | paginate : {
        itemsPerPage: pageSize,
        currentPage: p,
        totalItems: count
       
      };

      let i = index
     
      "
   
       [class.active]="i == currentIndex"
      >  
      
      <app-product-item [productItem]="product" [addedToWishlist]="wishlist.indexOf(product._id) >= 0"></app-product-item>
    
      </div>
       </div>

   <div class="col-md-12">
  <pagination-controls class="float-right"
    previousLabel="Prev"
    nextLabel="Next"
    [responsive]="true"
    (pageChange)="p = $event"
    (pageBoundsCorrection)="handlePageChange($event)"
    directionLinks="true">
  
  </pagination-controls>
</div>
</div>

  