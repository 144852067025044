
<p class="productlist-p">DEVASNDOLLSCHI` is your one stop shop providing you with the essentials to keep you up to speed in your beauty routine.<img  class="productlist-img"   src="assets/DollsandBags.png"></p> 
<h1 class="wishlistlist-hl">Wish List</h1>
<div *ngIf="wishitems.length === 0"class="alert alert-info">You Have No Wish Items</div> 
<div class="container wishlist-container">
    <div class="row">  
     
     <div class="col-md-2" *ngFor="let product of  wishitems | paginate : { 
      itemsPerPage: pageSize,
      currentPage: p,
      totalItems: count
     
    };

    let i = index
   
    "
 
     [class.active]="i == currentIndex"
    >  
       
      <app-wishlistitem [wishItem]="product"></app-wishlistitem>
        
 
    </div>
  </div>

 <div class="col-md-12">
  <pagination-controls class="float-right"
    previousLabel="Prev"
    nextLabel="Next"
    [responsive]="true"
    (pageChange)="p = $event"
    (pageBoundsCorrection)="handlePageChange($event)"
    directionLinks="true">
  
  </pagination-controls>
</div>

</div>
